import React from 'react'
import { useTheme, Flex } from '@chakra-ui/react'
import { PageLayout } from '../components/PageLayout'
import { Hero } from '../components/Hero'
import { EntitledList } from '../components/EntitledList'
import { NestedList } from '../components/NestedList'

export const Head = () => (
    <>
        <title>Project Plan | Orientier Proposal Hub for MassBay Community College Website Refresh</title>
        <meta name="robots" content="noindex" />
    </>
)

const hero = (
    <Hero heading="Project Plan" subheading="A high-level outline of our approach to the MassBay.edu website refresh" />
)

const IndexPage = () => {
    const {
        constants: { LIST_OL_NUMBERED },
    } = useTheme()

    return (
        <PageLayout hero={hero} pageSlug="project-plan">
            <Flex maxWidth="container.md" mx="auto" p={8} flexDir="column" gap={8}>
                <EntitledList
                    heading="Platform"
                    items={['Drupal 10', 'Pantheon hosting', 'Google Analytics with custom tracking', 'Cloudflare CDN']}
                />
                <EntitledList
                    heading="Key Features"
                    items={[
                        'A mix of content retention/migration, merging, archival, and creation',
                        'Intelligent content architecture designed to support the new design as well as future evolution',
                        'New library of components that can be used to build/augment pages throughout the site',
                        'Robust theming and responsive design that is thoughtful enough to handle a variety of content and layout changes',
                        'Responsive image styles with support for custom cropping and/or focal point selection',
                        'Images optimized for performance and accessibility',
                        'Solid defaults for meta tags and social sharing, with support for customizations',
                        'Rearchitected site navigation',
                        "Support for bespoke native language content authoring and interface translation for selected languages using Drupal's built-in multilingual capabilities",
                        'Google Translate integration for all other languages (tastefully implemented)',
                        'Media Library with support for various media types (including images, videos, and documents)',
                        'Media Library customization to improve your ability to find and manage media assets',
                        'Caching strategy to improve performance and reduce hosting costs without sacrificing content freshness',
                        'Improved faculty/staff/administration directory',
                        'Site search with faceting, content-type-specific results, search term highlighting, suggestions, etc.',
                        'New ways of presenting academic catalog data. Display course info in an attractive, user-friendly way. Show relationships between courses, prerequisites, programs, faculty, academic calendar, etc.',
                        'Program finder with helpful search and filtering options (e.g. by department, degree type, location, etc.)',
                        'Content, menus, and calls to action designed to empower key personas/user groups (e.g. high school students, MassReconnect students, other prospective students, current students, faculty, staff, first-generation college students, immigrants/asylees, etc.)',
                    ]}
                />
                <NestedList
                    heading="Timeline"
                    items={{
                        'September, 2023': [
                            'Kickoff meetings. Build rapport, establish roles and responsibilities, learn communication styles/preferences, etc.',
                            'Collaborative discovery process and stakeholder engagement',
                            'Provision team access to Pantheon and review current configuration',
                            'Establish technical strategy for developing and testing new site while maintaining current site. Provision Pantheon multidev sites, initiate an Integrated Composer model, etc.',
                            'Establish project management tools and processes that work well for both teams',
                            'Deep dive into current site',
                            'Draft community engagement plan',
                        ],
                        'September-November, 2023': [
                            'Hold multiple on-site listening sessions',
                            'Conduct additional workshops with representative groups',
                            'Synthesize and crystallize feedback',
                            'Translate feedback into action steps/features',
                            'Work with core stakeholders to triage and prioritize potential actions/features',
                        ],
                        'October-November, 2023': [
                            'Site map, navigation, and content architecture work',
                            'Iterative visual design process in consultation with MassBay design stakeholders',
                        ],
                        'December, 2023': [
                            'Complete iterative visual design process',
                            'Finalize site map, navigation, and content architecture work',
                        ],
                        'January-March, 2024': [
                            'Content/site architecture implementation on new dev site',
                            'Iterative development process',
                            'Selected content migration and transition work',
                        ],
                        'March-April 2024': [
                            'Regularly update dev/test sites with new features and gather feedback from stakeholders',
                            'Continue full-stack development, with focus shifting toward front-end dev/theming',
                            'Directory and academic catalog-related work',
                            'Additional content migration and transition work',
                        ],
                        'May-June, 2024': [
                            'Complete primary full-stack development process',
                            'Additional technical and user acceptance testing',
                            'Team training sessions',
                            'New content creation, updates, revisions, etc.',
                            'Empower content teams and address any issues that arise.',
                            'Triage potential issues according to pre/post-launch priority',
                            'Public beta availability and feedback solicitation',
                        ],
                        'June-July, 2024': [
                            'Pre-launch phases, including integration testing, load testing, etc.',
                            'CDN configuration and launch preparation',
                            'Final site tuning',
                            'Public site launch',
                        ],
                        'July-September, 2024': ['Post-launch support', 'Additional training and guidance'],
                    }}
                />
            </Flex>
        </PageLayout>
    )
}

export default IndexPage
