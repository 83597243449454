import React from 'react'
import { Box, Heading, ListItem, List, useTheme } from '@chakra-ui/react'

export const NestedList = ({ heading, items }) => {
    const {
        constants: { LIST_UL_BULLETED },
    } = useTheme()

    return (
        <Box>
            <Heading as="h4" size="lg">
                {heading}
            </Heading>
            <List as="ul" variant={LIST_UL_BULLETED}>
                {Object.keys(items).map((key, index) => (
                    <ListItem key={index}>
                        <Box mb={4} fontWeight="bold">
                            {key}
                        </Box>
                        <Box mb={8}>
                            <List as="ul" variant={LIST_UL_BULLETED} mb={4}>
                                {items[key].map((item, i) => (
                                    <ListItem key={i}>{item}</ListItem>
                                ))}
                            </List>
                        </Box>
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}
